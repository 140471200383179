@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-white text-black;

  }

  .dark body {
    @apply bg-gray-900 text-white;
  }
}

body, html {
  overscroll-behavior: none;
}

body {
  margin: 0;
  padding: 0;
  height: 100dvh;
  width: 100dvw;
  background-color: var(--tg-theme-secondary-bg-color) !important;
  color: var(--tg-theme-text-color);
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch !important;
  touch-action: none !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}

button {
  background-color: var(--tg-theme-button-color);
  color: var(--tg-theme-button-text-color);
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.loader-container {
  display: flex;
  background-color: var(--tg-theme-secondary-bg-color) !important;
  color: var(--tg-theme-text-color) !important;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.svg-icon {
  width: 45px;
  height: 45px;
  fill: var(--tg-theme-text-color) !important;
  animation: pulse 2s cubic-bezier(.4, 0, .6, 1) infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1);
    opacity: 0.2;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@layer utilities {
  .diagonal-stripes {
    background: repeating-linear-gradient(
                    45deg,
                    var(--tg-theme-button-color),
                    var(--tg-theme-section-bg-color) 2px,
                    var(--tg-theme-section-bg-color) 5px,
                    var(--tg-theme-section-bg-color) 10px
    );
  }
}
